@mixin font-smoothing {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

 }  // Based on http://codepen.io/javasteve99/pen/hDxpn
@mixin font-face($font-family, $file-path, $font-weight: normal, $font-style: normal) {
    @font-face {
      font-family: $font-family;
      src: url('#{$file-path}.eot');
      src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), url('#{$file-path}.woff2') format('woff2'), url('#{$file-path}.woff') format('woff'), url('#{$file-path}.ttf') format('truetype'), url('#{$file-path}.svg##{$font-family}') format('svg');
      font-weight: $font-weight;
      font-style: $font-style; }

    // Chrome for Windows rendering fix: http://www.adtrak.co.uk/blog/font-face-chrome-rendering/
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      @font-face {
        font-family: $font-family;
        src: url('#{$file-path}.svg##{$font-family}') format('svg'); } } }

@mixin clearfix() {
  *zoom: 1;

  &:before,
  &:after {
    content: "";
    display: table; }

  &:after {
    clear: both; } }

@mixin gradient-background($gcolor-one, $gcolor-two) {
  background: -moz-linear-gradient(90deg, $gcolor-one 0, $gcolor-two 100%);
  // background: -webkit-gradient(linear, 90deg, color-stop(0, B527FF), color-stop(100%, 53b3f9))
  background: -webkit-linear-gradient(90deg, $gcolor-one 0, $gcolor-two 100%);
  background: -o-linear-gradient(90deg, $gcolor-one 0, $gcolor-two 100%);
  background: -ms-linear-gradient(90deg, $gcolor-one 0, $gcolor-two 100%);
  // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1301FE', endColorstr='#F4F60C', GradientType='1')
  background: linear-gradient(90deg, $gcolor-one 0, $gcolor-two 100%); }
