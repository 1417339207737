.search-form {
	position: relative;

	> label {
		display: block;
		width: 100%;

		> input {
			display: block;
			width: 100%;
			border: 1px solid $light-gray;
			padding: 10px 15px;
			font-weight: normal;
			transition: all 0.15s ease-in-out;

			&:focus,
			&:active {
				outline: 2px solid $base-color; } } }

	input[type="submit"] {
		position: absolute;
		top: 0;
		display: none; } }
