.newsletter-form {
	width: 75%;
	margin-left: auto;
	margin-right: auto;
	border: 4px solid rgba($black, 0.1);
	border-radius: 4px; }

.field-inside {
	position: relative;
	padding: 4px;
	padding-left: 20px;
	background-color: $white;
	border-radius: 4px;

	> input[type="text"] {
		border: none;
		padding: 15px 10px;
		padding-left: 50px;
		color: $text-color;
		width: 100%;

		&:focus,
		&:active {
			outline-color: transparent; } }

	> button[type="submit"] {
		border: none;
		background-color: $base-color;
		border: 1px solid transparent;
		padding: 14px 35px;
		font-size: 1.125em;
		font-weight: 700;
		text-transform: uppercase;
		position: absolute;
		overflow: hidden;
		top: 50%;
		right: 4px;
		transform: translateY(-50%);
		transition: all 0.15s ease-in-out;

		&:hover {
			color: $base-color;
			background-color: $white;
			border-color: $base-color;
			border-radius: 4px; }

		> .ajax-loader {
			position: absolute;
			width: 40px;
			height: 40px;
			top: 0;
			left: 0;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			opacity: 0;

			> i {
				position: absolute;
				top: 13px;
				left: 2px; } }
		&.clicked {
			> .subscribe-text {
				opacity: 0; }
			> .ajax-loader {
				opacity: 1; } } }

	> i {
		color: $dark-gray;
		font-size: 2.25em;
		position: absolute;
		top: 50%;
		left: 25px;
		transform: translateY(-50%); } }

@media (max-width: 767px) {
	.newsletter-form {
		width: 100%;
		border-color: transparent;
		border-radius: 0; }

	.field-inside {
		background-color: transparent;
		padding: 0;
		border-radius: 0;
		> i {
			top: 8px;
			left: 10px;
			transform: none; }
		> input {
			display: block; }

		> button[type="submit"] {
			position: relative;
			top: inherit;
			right: inherit;
			transform: none;
			width: 100%;
			margin-top: 10px; } } }
