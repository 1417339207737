/* Global Rules */
body {
	color: $text-color;
	font-size: 16px;
	line-height: 26px;
	background-color: $body-color;
	word-wrap: break-word; }
a {
	transition: all 0.15s ease-in-out; }
img, figure {
	max-width: 100%;
	height: auto; }

.screen-reader-text {
	display: none; }

/* Placehoder */
::-webkit-input-placeholder {
	color: $dark-gray;
	font-style: italic; }

:-moz-placeholder {
	color: $dark-gray;
	font-style: italic; }

::-moz-placeholder {
	color: $dark-gray;
	font-style: italic; }

:-ms-input-placeholder {
	color: $dark-gray;
	font-style: italic; }

/* SVG */
.mask-svg {
	height: 0;
	width: 0;
 }	// position: absolute

/* Middle Image */
.middle-img {
	img {
		margin: 0 auto; } }

/* Overlay Effects */
.overlay {
	background: rgba($overlay-color, .8);
	color: $white;

	h1,h2,h3,h4,h5,h6 {
		color: $white; } }

.overflow-hidden {
	overflow: hidden; }

/* Section Title */
.section-title {
	color: $light-black;
	> span {
		color: $base-color; } }

.stripe {
	margin: 0px auto;
	text-align: center;
	position: relative;

	&:before {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: 18px;
		width: 15%;
		height: 1px;
		border-top: 2px dotted $light-black + 180; }
	&:after {
		content: "";
		position: absolute;
		top: 50%;
		right: 50%;
		margin-right: 18px;
		width: 15%;
		height: 1px;
		border-top: 2px dotted $light-black + 180; }
	> i {
		position: relative;
		top: 50%;
		width: 30px;
		height: 30px;
		font-size: 1em;
		line-height: 1.4em;
		border: 2px dotted $light-black + 180;
		padding: 3px;
		border-radius: 50%; } }

/* Section Description */
.section-description {
	font-size: 1.125em;
	padding-top: 10px; }

/* Section Styles */
.section-padding {
	padding: 100px 0;
	overflow: hidden; }

.top-no-padding {
	padding-top: 0; }

.bottom-no-padding {
	padding-bottom: 0; }

.top-padding-100 {
	padding-top: 100px; }

.top-padding-80 {
	padding-top: 80px; }

.top-padding-50 {
	padding-top: 50px; }

.top-padding-30 {
	padding-top: 30px; }

.top-padding-20 {
	padding-top: 20px; }

.extra-padding {
	padding-left: 10px;
	padding-right: 10px; }

.extra-padding-40 {
	padding-left: 40px;
	padding-right: 40px; }

.top-margin-80 {
	margin-top: 80px; }

.right-padding-50 {
	padding-right: 50px; }

[data-background="gray"] {
	background: $light-gray; }

[data-background="light-black"] {
	background: $light-black;

	h1,h2,h3,h4,h5,h6 {
    	color: $white; } }

[data-parallax="yes"] {
	background-size: cover;
	background-attachment: fixed; }
