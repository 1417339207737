.gradient {
	// Overlay
	.overlay {
		background: linear-gradient(90deg, rgba($gcolor-one, 0.85) 0, rgba($gcolor-two, 0.85) 100%);
		.section-title {
			color: $white;
			> span {
				color: $white; } }

		// Download Links
		.download-links {
			> .link-item {
				&:hover {
					background: $white;
					border-color: transparent;
					color: $light-black; } } } }
	// Achieves
	.achieve-item {
		> .icon {
			background: linear-gradient(90deg, $gcolor-one 0, $gcolor-two 100%); }
		> .inside {
			background: linear-gradient(90deg, $gcolor-one 0, $gcolor-two 100%); } }

	// Features
	.feature-items {
		&.style-2 {
			.item {
				> .icon {
					border-color: transparent;
					border-image: linear-gradient(90deg, $gcolor-one 0, $gcolor-two 100%);
					border-image-slice: 1; }

				&:hover {
					> .icon:after {
						background: linear-gradient(90deg, $gcolor-one 0, $gcolor-two 100%); } } } }
		&.style-3 {
			.item {
				> .icon {
					border: none;
					background: linear-gradient(90deg, $gcolor-one 0, $gcolor-two 100%);

					&:after {
						content: '';
						position: absolute;
						top: 3px;
						right: 3px;
						bottom: 3px;
						left: 3px;
						background-color: $body-color;
						border-radius: 50%;
						z-index: 1; }
					&:before {
						z-index: 2;
						top: 6px;
						right: 6px;
						bottom: 6px;
						left: 6px; }

					> i {
						z-index: 3; } }

				&:hover {
					> .icon:before {
						background: linear-gradient(90deg, $gcolor-one 0, $gcolor-two 100%); } } } } }

	// Team Member
	.member-profile {
		> .member-image {
			background: linear-gradient(90deg, $gcolor-one 0, $gcolor-two 100%); } }

	// Pricing Tables
	.pricing-plan {
		> .head {
			background: linear-gradient(90deg, $gcolor-one 0, $gcolor-two 100%); } }

	// Screenshots
	.screenshot-item {
		> .zoom-overlay {
			background: linear-gradient(90deg, rgba($gcolor-one, 0.8) 0,rgba($gcolor-two, 0.8) 100%); } }

	// Blogs
	// .article-item
	// 	> .article-head
	// 		> .article-meta
	// 			background: linear-gradient(90deg, $gcolor-one 0, $gcolor-two 100%)

	// Testimonials
	.overflow-testimonials {
        > .item {
            &.active {
                > .image {
                    background: linear-gradient(90deg, $gcolor-one 0, $gcolor-two 100%); } } } }
	// Footer Color
	.footer-section {
		background-color: $white; } }
