.testimonial-container {
    padding-top: 80px;
    @include clearfix(); }
.testimonial-items {
    height: 275px;

    .item {
        padding: 0 15px;
        position: relative;

        > .content {
            background: $light-gray;
            padding: 30px;
            color: $text-color;
            border-radius: 4px;
            font-weight: 600;
            font-style: italic;
            position: relative;
            top: 50%;
            transform: translateY(-50%);

            &:before {
                content: "";
                border-style: solid;
                border-width: 15px 15px 15px 0px;
                border-color: transparent;
                border-right-color: $light-gray;
                position: absolute;
                top: 50%;
                margin-top: -15px;
                left: -15px; }

            > .info {
                margin-top: 25px;
                font-weight: 400;
                font-style: normal;

                > .rating {
                    unicode-bidi: bidi-override;
                    direction: rtl;

                    > span {
                        &.star {}
                        font-family: FontAwesome;
                        font-weight: normal;
                        font-style: normal;
                        display: inline-block;

                        &.star:before {
                            content: "\f006";
                            padding-left: 5px; }

                        &.star.filled:before {
                            content: "\f005";
                            color: $base-color; } } } } } } }

.testimonial-navigation {
    height: 275px;
    width: 115px;
    float: right;
    position: relative;
    > i {
        position: absolute;
        top: -32px;
        font-size: 2em;
        color: $base-color;
        left: 50%;
        transform: translateX(-50%);
        z-index: 555;

        &.swiper-button-disabled {
            color: $light-gray - 100; }

        &[data-carousel="prev"] {
            top: auto;
            bottom: -32px; } }
    > .swiper-container {
        height: 100%;
        width: 100%;
        overflow: hidden;

        > .nav-items {
            > .item {
                position: relative;

                > img {
                    width: 65px;
                    height: 65px;
                    border-radius: 50%;
                    border: 3px solid $light-gray;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%); }

                &.swiper-slide-active {
                    > img {
                        width: 105px;
                        height: 105px;
                        border: 4px solid $base-color;
                        transition: all 0.30s ease-in-out; } } } } } }

@media (max-width: 992px) {
    .testimonial-items {
        .item {
            > .content {
                width: 100%;

                &:before {
                    content: "";
                    border-style: solid;
                    border-width: 15px 15px 15px 15px;
                    border-color: transparent transparent $light-gray transparent;
                    position: absolute;
                    top: -14px;
                    margin-left: -15px;
                    left: 50%; } } } }

    .testimonial-navigation {
        width: 275px;
        height: 115px;
        float: none;
        margin: 0 auto;

        > i {
            top: 50%;
            margin-top: -10px;
            left: -32px;
            transform: rotate(-90deg);

            &[data-carousel="prev"] {
                top: 50%;
                left: inherit;
                bottom: inherit;
                right: -32px; } } } }


// Overflow Testimonials
.overflow-testimonials {
    position: relative;
    width: 962px;
    height: 435px;
    margin: 0 auto;
    overflow: hidden;
    background-image: url('../images/ov-t-bg.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;

    > .item {
        position: absolute;
        overflow: hidden;

        > .image {
            width: 83px;
            height: 83px;
            border-radius: 50%;
            padding: 3px;
            background-color: $dark-gray;

            > img {
                width: 100%;
                height: auto;
                border-radius: 50%;
                &:hover {
                    cursor: pointer; } } }

        > .content {
            position: absolute;
            top: 108px;
            font-size: 1.125em;
            width: 850px;
            height: auto;
            padding: 40px;
            border: 1px solid $dark-gray;
            border-bottom: none;
            border-radius: 4px;
            background-color: rgba($white,0.8);
            box-shadow: 0px 2px 4px 0px rgba($dark-gray, 0.5);
            transition: all 0.15s ease-in-out;
            opacity: 0;

            &:before,
            &:after {
                bottom: 100%;
                left: 225px;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: transparent;
                border-bottom-color: $white;
                border-width: 20px;
                margin-bottom: -1px; }
            &:before {
                border-bottom-color: $dark-gray;
                margin-bottom: 0px; }


            .comment {
                font-style: italic;
                margin-bottom: 25px; }

            > .info {
                line-height: 1em;

                > .name {
                    font-weight: 600; }

                > .designation {
                    margin-left: 15px;
                    padding-left: 15px;
                    display: inline-block;
                    position: relative;

                    &:before {
                        content: "";
                        position: absolute;
                        left: 0px;
                        height: 100%;
                        width: 2px;
                        background-color: $dark-gray; } } } }
        &.active {
            overflow: visible;
            z-index: 101;

            > .content {
                opacity: 1; }

            > .image {
                background-color: $base-color; } }


        &:nth-child(1) {
            top: 10px;
            left: 270px;

            > .content {
                top: 100px;
                left: -210px; }

            &.active {
                > .content {
                    top: 120px; } } }

        &:nth-child(2) {
            top: 90px;
            left: 50%;
            transform: translateX(-50%);

            > .content {
                top: 100px;
                left: -380px;

                &:before,
                &:after {
                    left: 400px; } }

            &.active {
                > .content {
                    top: 120px; } } }

        &:nth-child(3) {
            top: 10px;
            right: 270px;

            > .content {
                top: 100px;
                left: inherit;
                right: -200px;

                &:before,
                &:after {
                    left: inherit;
                    right: 220px; } }

            &.active {
                > .content {
                    top: 120px; } } }

        &:nth-child(4) {
            top: 235px;
            left: 400px;
            transform: translateX(-50%);

            > .content {
                top: -200px;
                left: -300px;

                &:before,
                &:after {
                    bottom: -39px;
                    left: 320px;
                    border-bottom-color: transparent;
                    border-top-color: $white; }

                &:before {
                    border-bottom-color: transparent;
                    border-top-color: $dark-gray;
                    filter: blur(2px);
                    bottom: -42px; } }
            &.active {
                > .content {
                    top: -220px; } } }

        &:nth-child(5) {
            top: 235px;
            right: 310px;
            transform: translateX(-50%);

            > .content {
                top: -200px;
                left: -450px;

                &:before,
                &:after {
                    bottom: -38px;
                    left: 470px;
                    border-bottom-color: transparent;
                    border-top-color: $white; }

                &:before {
                    border-bottom-color: transparent;
                    border-top-color: $dark-gray;
                    filter: blur(2px);
                    bottom: -42px; } }
            &.active {
                > .content {
                    top: -220px; } } } } }
