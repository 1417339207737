// @import generated/icons

/* Table of contents */
/* ---------------------------------------------------------------------- /
/*  1 - General / Global Styles 										 */
/*  1.1 - Buttons 				 										 */
/*  1.2 - Controls 				 										 */
/*  1.3 - Typography			 										 */
/*  2.0 - Navigation			 										 */
/*  3.0 - Banners			 										 	 */
/*  4.0 - Archives			 										 	 */
/*  5.0 - Features			 										 	 */
/*  6.0 - Download Links	 										 	 */
/*  7.0 - Pricing	 												 	 */
/*  8.0 - Testimonials 												 	 */
/*  9.0 - Screenshots 												 	 */
/*  10.0 - Blog Archives 												 */
/*  11.0 - Blog 												 		 */
/*  12.0 - Newsletter 												 	 */
/*  13.0 - Contact  												 	 */
/*  14.0 - Team  												 	     */
/*  15.0 - Logo  												 	     */
/*  16.0 - Footer  												 	     */
/*  17.0 - Gradient Style										 	     */
/*  18.0 - Dark Style										 	         */
/*  19.0 - Sidebar And Widgets									 	     */
/*  19.1 - Widget - Archives									 	     */
/*  19.2 - Widget - Calendar									 	     */
/*  19.3 - Widget - Search									 	         */
/*  19.4 - Widget - Tag Cloud								 	         */
/*  20.0 - Internet Explorer Fix							 	         */
/* --------------------------------------------------------------------- */

/* 1.0 - General / Global Styles */
@import base/variables
@import base/mixins
@import base/defaults
@import base/preloader

/* 1.1 - Buttons */
@import base/buttons

/* 1.2 - Controls */
@import base/controls

/* 1.3 - Typography */
@import base/typography

/* 2.0 - Navigation */
@import base/navigation

/* 3.0 - Banners */
@import base/banner

/* 4.0 - Achieves */
@import base/achieve

/* 5.0 - Features */
@import base/feature

/* 6.0 - Download Links */
@import base/download-link

/* 7.0 - Pricing */
@import base/pricing

/* 8.0 - Testimonials */
@import base/testimonial

/* 9.0 - Screenshots */
@import base/screenshot

/* 10.0 - Blog Archives */
@import base/blog-archives

/* 11.0 - Blog */
@import base/blog

/* 12.0 - Newsletter */
@import base/newsletter

/* 13.0 - Contact */
@import base/contact

/* 14.0 - Team */
@import base/team

/* 15.0 - Logo */
@import base/logo

/* 16.0 - Footer */
@import base/footer

/* 17.0 - Gradient Style */
@import base/gradient

/* 18.0 - Dark Style */
@import base/dark

/* 19.0 - Sidebar And Widgets */
@import base/sidebar

/* 19.1 - Widget - Archives */
@import widgets/archives

/* 19.2 - Widget - Calendar */
@import widgets/calendar

/* 19.3 - Widget - RSS Feed */
@import widgets/rss

/* 19.4 - Widget - Search */
@import widgets/search

/* 19.5 - Widget - Tag Cloud */
@import widgets/tag-cloud

/* 20.0 - Internet Explorer Fix*/
@import base/iefix

// Temp
@import base/temp
