.article-item {
	background-color: $white;
	padding: 6px;
	padding-bottom: 30px;
	margin-bottom: 25px;
	position: relative;
	box-shadow: 0px 2px 4px 0px rgba($light-black, 0.1);

	> .article-head {
		position: relative;

		> .article-image {
			overflow: hidden;
			margin-bottom: 70px;

			> img {
				width: 100%;
				height: auto; } }

		> .article-meta {
			display: inline-block;
			padding: 20px 10px;
			color: rgba($white, 0.5);
			background-color: $base-color;
			text-align: center;
			left: 50%;
			bottom: 0%;
			transform: translate(-50%, 50%) skewY(1deg);
			position: absolute;
			width: 90%;
			z-index: 10;

			> .meta-item {
				display: inline-block;
				padding: 0 25px;
				border-right: 1px solid rgba($light-blue, 0.3);
				transform: skewY(-1deg);

				> i {
					display: block; }
				span {
					color: $white;
					font-weight: 600; }

				&:last-child {
					border-right: none; } } } }

	> .entry-title {
		font-size: 1.375em;
		text-align: center;
		padding: 0 50px;

		> a {
			color: $light-black;
			text-decoration: none;
			&:hover {
				color: $base-color; } } }

	> .entry-content {
		text-align: center;
		padding: 0 50px;
		margin-bottom: 30px; }

	> .readmore {
		display: table;
		margin: 0 auto; } }

// Timeline Blog
.timeline {
	position: relative;
	overflow: hidden;
	padding-bottom: 85px;

	&:before {
		content: "";
		position: absolute;
		width: 4px;
		height: 100%;
		background-color: $dark-gray;
		top: 0;
		left: 50%;
		transform: translateX(-50%); }

	.article-item {
		width: 45%;
		float: left;
		clear: left;

		&:before {
			content: "";
			width: 30px;
			height: 30px;
			background-color: $light-gray;
			border: 5px solid $dark-gray;
			border-radius: 50%;
			position: absolute;
			top: 35px;
			right: -72px;
			transition: all 0.15s ease-in-out; }

		&:hover {
			&:before {
				background-color: $base-color; } }

		> .article-head {

			&:before {
				content: "";
				position: absolute;
				top: 30px;
				right: -38px;
				width: 0;
				height: 0;
				border: 14px solid transparent;
				border-left-color: rgba($light-black, 0.2);
				filter: blur(2px); }

			&:after {
				content: "";
				position: absolute;
				top: 26px;
				right: -42px;
				width: 0;
				height: 0;
				border: 18px solid transparent;
				border-left-color: $white; }

			> .corner {
				width: 15px;
				height: 26px;
				position: absolute;
				top: 31px;
				right: -15px;
				background-position: 14px -35px;
				z-index: 10; } }


		&:nth-child(even) {
			margin-top: 70px;
			margin-left: 10%;
			clear: right;

			&:before {
				right: inherit;
				left: -72px; }

			> .article-head {

				&:before {
					right: inherit;
					left: -38px;
					border-left-color: transparent;
					border-right-color: rgba($light-black, 0.2); }

				&:after {
					right: inherit;
					left: -42px;
					border-left-color: transparent;
					border-right-color: $white; }

				> .corner {
					right: inherit;
					left: -15px;
					background-position: 0px -41px;
					clip-path: polygon(100% 0, 0 50%, 100% 100%);
 } } } }					// clip-path: url(../images/mask.svg#aw-angle-left-shape)

	.morepost-btn {
		position: absolute;
		z-index: 50;
		bottom: 0px;
		left: 50%;
		transform: translateX(-50%);

		> .viewmore {
			display: table;
			margin: 0 auto; } } }

// Responsive
@media (max-width: 768px) {
	.timeline {
		&:before {
			display: none; }

		.article-item {
			width: 100%;
			float: none;

			&:before,
			&:after {
				display: none; }

			.article-head {
				.corner,
				&:before,
				&:after {
					display: none; } }

			&:nth-child(even) {
				margin-left: 0; } } } }

@media (min-width: 768px) {
	.timeline {
		&:before {
			display: none; }

		.article-item {
			width: 100%;
			float: none;

			&:before,
			&:after {
				display: none; }

			.article-head {
				.corner,
				&:before,
				&:after {
					display: none; } }

			&:nth-child(even) {
				margin-left: 0; } } } }

@media (min-width: 992px) {
	.timeline {
		&:before {
			display: block; }

		.article-item {
			width: 45%;
			float: left;

			&:before,
			&:after {
				display: block; }

			&:before {
				right: -62px; }

			> .article-head {
				.corner,
				&:before,
				&:after {
					display: block; }

				> .article-meta {
				 > .meta-item {
				 	padding: 0 10px; } } }

			&:nth-child(even) {
				margin-left: 10%;

				&:before {
					left: -62px; } } } } }

@media (min-width: 1200px) {
	.timeline {
		.article-item {
			&:before {
				right: -72px; }

			&:nth-child(even) {
				&:before {
					left: -72px; } } } } }

// Full Width
.full-width {
	.aw-single-post {
		> article {
			border: none;
			margin: 0; }

		.aw-post-content {
			padding: 0;
			margin: 0;
			border: none;

			> .title {
				font-size: 2em; } } } }

// Pagination
.aw-post-pagination {
	> ul {
		> li {
			> a,
			> span {
				border-color: $light-gray;
				padding: 10px 20px;
				font-size: 1.2em;
				font-weight: 600;

				&:hover {
					background-color: $base-color;
					color: $white; } } } } }
