.download-links {

	> .link-item {
		display: inline-block;
		text-decoration: none;
		color: $white;
		padding: 15px 40px;
		border: 2px solid $white;
		border-radius: 4px;
		margin: 10px;
		transition: all 0.15s ease-in-out;

		> i {
			width: 50px;
			font-size: 3em;
			float: left; }

		> .heading {
			margin-left: 60px;
			display: block;
			font-size: .9375em;
			color: $text-color + 100; }

		> .name {
			margin-left: 60px;
			display: block;
			font-family: $alt-family;
			font-size: 1.25em;
			font-weight: 700; }

		&:hover {
			background-color: $base-color;
			border-color: $base-color; }

		&:first-child {
			margin-left: 0px; }
		&:last-child {
			margin-right: 0px; } } }
