.screenshots-carousel {
	position: relative;
	padding-left: 70px;
	padding-right: 70px;

	.bullet-pagination {
		margin-top: 40px; }
	.arrow-control {
		&.next {
			transform: translate(50%, -50%); }
		&.prev {
			transform: translate(50%, -50%); } } }

.screenshot-item {
	position: relative;

	> img {
		width: 100%;
		height: auto; }

	> .zoom-overlay {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		background-color: rgba($light-black, 0.8);
		color: $white;
		transition: all 0.15s ease-in-out;
		opacity: 0;

		> i {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 25px;
			border: 2px solid $white;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			transition: all 0.15s ease-in-out;

			&:before,
			&:after {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-color: $white; }
			&:before {
				width: 1px;
				height: 20px; }
			&:after {
				width: 20px;
				height: 1px; }

			&:hover {
				background-color: rgba($base-color, 0.3); } }
		&:hover {
			opacity: 1; } } }
