.blog,
.single,
.blog-container {
	background: $white; }

.aw-bredcrumb {
	margin: 0;
	padding: 0;

	> li {
		display: inline-block;
		position: relative;
		padding-right: 12px;

		&:before {
			content: "";
			display: inline-block;
			width: 5px;
			height: 5px;
			background: $text-color;
			border-radius: 50%;
			position: absolute;
			top: 50%;
			margin-top: -2.5px;
			left: -10px; }
		&:first-child:before {
			display: none; } } }

.transparent {
	.aw-bredcrumb {
		> li {
			&:before {
				background-color: $white; } } } }

.aw-blog-posts {
	> article {
		border: 1px solid $light-gray;
		padding: 5px;
		margin-bottom: 25px;
		> img {
			width: 100%;
			height: auto;
			text-align: center; }

		.aw-post-content {
			padding: 25px;

			> h2 {
				margin: 0;
				margin-bottom: 15px;
				font-size: 1.375em;

				> a {
					color: $light-black;
					text-decoration: none;
					&:hover {
						color: $base-color; } } }

			> .meta {
				margin-bottom: 15px;

				> ul {
					list-style: none;
					margin: 0;
					padding: 0;

					> li {
						display: inline-block;
						margin-right: 5px;
						> a {
							color: $text-color; } } } }

			> .aw-post-excerpt {
				margin-bottom: 15px; }

			> .aw-btn {
				margin: 0; } } } }
// Single Post
.aw-single-post {
	> article {
		border: 1px solid $light-gray;
		padding: 5px;
		padding-bottom: 25px;
		margin-bottom: 25px;

		> .aw-post-thumb {
			max-width: 100%;
			height: auto; }

		.aw-post-content {
			padding: 25px;

			> .title {
				margin: 10px 0 25px 0;
				font-size: 1.375em;
				color: $light-black; }
			p {
				margin-bottom: 30px; }

			// Post meta
			> .meta {
				display: table;
				float: left;
				> ul {
					margin: 0;
					padding: 0;

					> li {
						display: inline-block;
						margin-right: 5px;
						color: $dark-gray;

						> a {
							color: $dark-gray; } } } }

			// Post Share
			> .post-share {
				display: table;
				float: right;
				> .share-text {
					color: $dark-gray;
					font-weight: 600; }
				> ul {
					padding: 0;
					margin: 0;
					display: inline-block;

					> li {
						display: inline-block;

						> a {
							position: relative;
							padding: 0 5px;
							display: block;

							> span {
								display: none; }

							> i {
								font-size: 1.25em;

								&[class*="facebook"] {
									color: #3b5998; }
								&[class*="twitter"] {
									color: #28a9e2; }
								&[class*="pinterest"] {
									color: #cb2027; }
								&[class*="google"] {
									color: #d95232; } } } } } } } } }

// Author Bio
.aw-post-author {
	padding: 20px;
	border: 1px solid $light-gray;
	margin-bottom: 25px;
	@include clearfix();

	> .author-img {
		width: 145px;
		height: 145px;
		background-color: $light-gray;
		padding: 4px;
		border-radius: 50%;
		float: left;

		> img {
			width: 100%;
			height: 100%;
			border-radius: 50%; } }

	> .author-details {
		margin-left: 170px;

		> h3 {
			margin-top: 0;
			font-size: 1.2em;
			margin-bottom: 10px; } } }

// Comments
.aw-comments {
	border: 1px solid $light-gray;
	padding: 45px 25px;
	padding-bottom: 10px;
	margin-bottom: 25px;

	> h2 {
		margin: 0;
		margin-bottom: 30px;
		font-size: 1.375em;
		text-transform: uppercase; }

	.commentslist {
		padding: 0; }

	ol, ul {
		> li {
			list-style: none;

			> article {
				margin-bottom: 25px;
				@include clearfix();

				> .comment-author-img {
					width: 80px;
					height: 80px;
					background-color: $light-gray;
					padding: 3px;
					border-radius: 50%;
					float: left;

					> img {
						width: 100%;
						height: 100%;
						border-radius: 50%; } }

				> .comment-details {
					margin-left: 100px;

					.comment-meta {
						margin-bottom: 15px;

						> h3 {
							margin-top: 0;
							margin-bottom: 5px;
							font-size: 1em; }

						> .time {
							font-size: 0.8em;
							> a {
								color: $dark-gray; } } } }

				> .comment-reply-link {
					display: inline-block;
					float: right;
					background-color: $base-color;
					color: $white;
					line-height: 1em;
					padding: 5px 10px;
					text-decoration: none;
					border: 1px solid transparent;
					border-radius: 2px;

					&:hover {
						color: $base-color;
						background-color: transparent;
						border: 1px solid $base-color; } } } } } }

// Comment Form
.comment-respond {
	border: 1px solid $light-gray;
	padding: 25px;
	margin-bottom: 25px;

	> h3 {
		margin: 0;
		margin-bottom: 25px;
		font-size: 1.375em;
		text-transform: uppercase; }

	> form {
		> input,
		> textarea {
			width: 100%;
			border: 1px solid $light-gray;
			padding: 10px 15px;
			margin-bottom: 10px;
			transition: all 0.15s ease-in-out;

			&:focus,
			&:active {
				outline: 2px solid $base-color; } }

		> .form-submit {
			margin: 0;
			padding: 0;

			> input[type="submit"] {
				width: 100%;
				background-color: $base-color;
				border: 2px solid $base-color;
				padding: 10px 0px;
				color: $white;
				font-weight: 600;
				text-transform: uppercase;
				font-family: $alt-family;
				transition: all 0.15s ease-in-out;

				&:hover {
					background-color: transparent;
					color: $base-color; } } } } }
