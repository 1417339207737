.widget {
	margin-bottom: 50px;

	> .widget-title {
		font-size: 1.375em;
		margin: 0;
		margin-bottom: 30px;
		text-transform: uppercase;
		color: $base-color; }

	select {
		max-width: 100%;
		border: 1px solid $light-gray;
		padding: 10px 15px;

		&:focus,
		&:active {
			outline: 2px solid $base-color; } } }

.wp-caption {
	background: $white;
	border: 1px solid $light-gray;
	padding: 5px;
	text-align: center;
	margin-bottom: 15px;


	> p.wp-caption-text {
		font-size: 1em;
		line-height: 1em;
		padding-top: 5px;
		margin: 0; } }
