#preloader {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgb(15, 22, 30);
	z-index: 9999; }
#loader {
	position: absolute;
	top: 50%;
	left: 50%;

	> .loader-inner > div {} }
