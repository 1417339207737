.widget_recent_entries,
.widget_recent_comments,
.widget_categories,
.widget_archive,
.widget_pages,
.widget_meta,
.widget_nav_menu > div, {

	> ul {
		margin: 0;
		padding: 0;
		padding-left: 5px;

		li {
			list-style: none;
			display: block;

			> a {
				color: $text-color;
				text-decoration: none;
				transition: all 0.15s ease-in-out;

				&:hover {
					color: $base-color; } }

			&:before {
				content: "\f105";
				font-family: FontAwesome;
				text-align: center;
				font-size: 0.8em;
				line-height: 1.20em;
				font-weight: bold;
				color: $white;
				width: 15px;
				height: 15px;
				display: inline-block;
				background-color: $dark-gray;
				padding-left: 2px;
				border-radius: 50%;
				margin-right: 20px;
				transition: all 0.15s ease-in-out; }

			&:hover {
				&:before {
					background-color: $base-color; } } } } }

