// Colors
$body-color: #f2faff;
$base-color: #4798d9;
$light-gray: #e5eff6;
$dark-gray: #bdd1de;
$deep-gray: #1e2429;
$deep-gray-2: #182028;
$light-blue: #aed6f1;
$light-black: #1d252e;
$text-color: #5b7182;
$overlay-color: #0f161e;
$white: #FFFFFF;
$black: #000000;

// Dark Styles
$dark-color-1: #28323d;
$dark-color-2: #182028;

// Gradient
$gcolor-one: #B527FF;
$gcolor-two: #53b3f9;

// Font families
$default-family: 'Source Sans Pro', sans-serif;
$alt-family: 'Montserrat', sans-serif;
