.feature-items {

	.item {
		margin-bottom: 40px;

		> .icon {
			width: 60px;
			height: 60px;
			float: left;

			> i {
				font-size: 2.5em;
				color: $text-color + 90; } }
		> .content {
			margin-left: 70px;

			> h3 {
				color: $base-color; } } }
	&.style-2 {

		.item {
			margin-bottom: 0;
			padding-bottom: 30px;
			position: relative;

			> .icon {
				width: 80px;
				height: 70px;
				position: relative;
				border: 2px solid $base-color;
				text-align: center;
				transform: skewY(-5deg);
				transition: all 0.15s ease-in-out;

				// &:before,
				&:after {
					content: "";
					display: block;
					position: absolute;
					top: 4px;
					right: 4px;
					bottom: 4px;
					left: 4px;
					transition: all 0.15s ease-in-out; }


				> i {
					color: $base-color;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%); } }

			> .content {
				margin-left: 100px;
				position: relative;

				&:after {
					content: "";
					position: absolute;
					height: 89%;
					width: 1px;
					border-left: 1px dashed $base-color;
					left: -60px;
					top: 65px;
					z-index: 1; } }

			&:hover {
				> .icon {
					&:after {
						background-color: $base-color; }

					> i {
						color: $white;
						z-index: 300; } } }

			&:before,
			&:after {
				content: "";
				position: absolute;
				width: 10px;
				height: 10px;
				background-color: $base-color;
				border-radius: 50%;
				top: -4px;
				left: 40px;
				margin-left: -4.5px;
				z-index: 3; }

			&:after {
				top: 65px; }

			&:last-child {

				&:after {
					display: none; }

				> .content {
					&:after {
						display: none; } } }

			&:first-child {
				&:before {
					display: none; } } } }
	&.style-3 {
		.item {
			min-height: 100px;
			> .icon {
				float: none;
				margin: 0 auto;
				width: 102px;
				height: 102px;
				border: 3px solid $base-color;
				padding: 4px;
				border-radius: 50%;
				position: relative;
				z-index: 50;

				&:before {
					content: "";
					position: absolute;
					top: 4px;
					right: 4px;
					bottom: 4px;
					left: 4px;
					border-radius: 50%;
					z-index: -48;
					transition: all 0.15s ease-in-out; }

				> i {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					color: $text-color; } }

			> .content {
				margin: 0;
				text-align: center;

				> h3 {
					color: $light-black; } }

			&:hover {
				> .icon {
					> i {
						color: $white; }
					&:before {
						background: $base-color; } } } }

		&.left-icon {
			.item {
				> .icon {
					float: left; }
				> .content {
					text-align: inherit;
					margin-left: 120px; } } }
		&.right-icon {
			.item {
				> .icon {
					float: right; }
				> .content {
					text-align: right;
					margin-right: 120px; } } }

		&.list-style {
			.item {
				position: relative;

				&:before,
				&:after {
					content: "";
					width: 10px;
					height: 10px;
					background-color: $base-color;
					border-radius: 50%;
					position: absolute;
					top: -3px;
					left: 51px;
					margin-left: -5px;
					z-index: 51; }
				&:after {
					top: 96px; }

				.content {

					&:before {
						content: "";
						position: absolute;
						height: 65%;
						width: 1px;
						border-left: 1px dashed $base-color;
						left: 50px;
						top: 80px; } }

				&:first-child {
					&:before {
						display: none; } }

				&:last-child {
					&:after {
						display: none; }

					> .content {
						&:before {
							display: none; } } } }
			&.right-icon {
				.item {
					&:before,
					&:after {
						left: inherit;
						right: 51px;
						margin-right: -5px;
						z-index: 51; }
					&:after {
						top: 96px; }

					.content {
						&:before {
							left: inherit;
							right: 50px; } } } } }





		&.small {
			.item {
				> .icon {
					width: 86px;
					height: 86px;

					> i {
						font-size: 1.6em; } } }
			&.left-icon {
				.item {
					> .icon {
						float: left; }
					> .content {
						text-align: inherit;
						margin-left: 100px; } } }
			&.right-icon {
				.item {
					> .icon {
						float: right; }
					> .content {
						text-align: right;
						margin-right: 100px; } } }
			&.list-style {
				.item {
					&:before,
					&:after {
						left: 43px;
						margin-left: -5px;
						z-index: 51; }
					&:after {
						top: 80px; }

					.content {
						&:before {
							left: 43px; } } }
				&.right-icon {
					.item {
						&:before,
						&:after {
							left: inherit;
							right: 44px;
							margin-left: inherit;
							margin-right: -5px;
							z-index: 51; }
						&:after {
							top: 80px; }

						.content {
							&:before {
								left: inherit;
								right: 44px; } } } } } } } }
