.bullet-pagination {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
	padding: 10px 0;

	> span {
		display: inline-block;
		width: 15px;
		height: 15px;
		margin: 2px;
		border: 3px solid $text-color;
		border-radius: 50%;
		background: transparent;
		opacity: 0.5;

		&.active, &.swiper-pagination-bullet-active {
			border-color: $base-color;
			background-color: $base-color;
			opacity: 1; } } }

.arrow-control {
	position: absolute;
	width: 30px;
	height: 60px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 100;
	background-image: none;
	font-size: 100px;
	color: $base-color;
	cursor: pointer;

	&.next {
		right: 25px;

		&:before,
		&:after {
			content: "";
			display: block;
			position: absolute;
			width: 1px;
			height: 40px;
			background-color: $base-color;
			right: 15px; }
		&:before {
			transform: rotate(-45deg);
			top: -4px; }
		&:after {
			transform: rotate(45deg);
			bottom: -4px; } }

	&.prev {
		left: 0px;
		&:before,
		&:after {
			content: "";
			display: block;
			position: absolute;
			width: 1px;
			height: 40px;
			background-color: $base-color;
			right: 15px; }
		&:before {
			transform: rotate(45deg);
			top: -4px; }
		&:after {
			transform: rotate(-45deg);
			bottom: -4px; } }
	&.inactive, &.disabled, &.swiper-button-disabled {
		&:before,
		&:after {
			background-color: rgba($text-color, 0.2); } } }
