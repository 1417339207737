.banner-section {
	margin-bottom: 50px;
	.overlay {
		> .container {
			padding: 0; } }

	.banner-middle-content {
		position: relative;
		top: 50%;
		transform: translateY(-50%); }

	.banner-content {

		.banner-title {
			font-size: 2.625em;
			line-height: 1.2em;
			margin-bottom: 25px; }

		.banner-description {
			font-size: 1.125em;
			line-height: 1.3em;
			margin-bottom: 25px; } }

	.application-mockup {
		position: relative;

		.overlay-image {
			position: absolute;
			left: 50%;
			bottom: -60px;
			transform: translateX(-50%);
			height: 100%;
			max-height: 750px; }

		.banner-image-2 {
			height: auto;
			position: absolute;
			bottom: 0px;
			height: 75%; } } }

@media (max-width: 410px) {
	.banner-section {
		.banner-content {
			.action-buttons {
				> a {
					display: block;
					margin: 10px 0;
					text-align: center; } } } } }


@media only screen and (min-device-width: 320px) and (max-device-width: 736px) {
	.banner-section {
		.banner-content {

			.banner-title {
				font-size: 2em; }

			.banner-description {
				font-size: 1em; }

			.action-buttons {
				> a {
					display: block;
					margin: 10px 0;
					text-align: center; } } }

		.application-mockup {
			display: none; } } }

@media only screen and (min-device-width: 320px) and (max-device-width: 736px) and (orientation: landscape) {
	.banner-section {
		.banner-content {
			.action-buttons {
				overflow: hidden;
				// width: 90%
				text-align: center;
				> a {
					display: inherit;
					// width: 20%
					float: left;

					&:last-child {
						margin-left: 5%; } } } } } }
