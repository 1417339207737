.achieve-item {
	margin-top: 100px;
	padding: 0 10px;
	position: relative;


	> .icon {
		width: 100px;
		height: 100px;
		background-color: #FFF;
		position: absolute;
		left: 50%;
		margin-left: -50px;
		top: -50px;
		background-color: $base-color;
		text-align: center;
		border-radius: 50%;
		z-index: 2;

		&:after {
			content: "";
			position: absolute;
			top: 3px;
			left: 3px;
			width: 94px;
			height: 94px;
			background: $body-color;
			border-radius: 50%;
			z-index: -1; }

		> i {
			font-size: 2.5em;
			color: $base-color;
			line-height: 2.5em; } }

	> .inside {
		min-height: 150px;
		padding-top: 65px;
		padding-right: 25px;
		padding-bottom: 65px;
		padding-left: 25px;
		text-align: center;
		background-color: $base-color;
		color: $white;
		position: relative;
		overflow: hidden;
		transform: skewY(-6deg);

		> .content {
			font-size: 2.25em;
			font-weight: 700;
			line-height: 1.2em;
			font-family: $alt-family;
			transform: skewY(6deg); }

		> .name {
			font-size: 1.125em;
			line-height: 2em;
			color: rgba($white, 0.5);
			transform: skewY(6deg); } } }
