.widget_tag_cloud {

	> .tagcloud {

		> a {
			font-size: 0.8em !important;
			font-weight: 600;
			border: 1px solid $light-gray;
			padding: 5px 10px;
			margin: 3px;
			text-transform: uppercase;
			display: inline-block;
			text-decoration: none;

			&:hover {
				background-color: $base-color;
				color: $white; } } } }
