.widget_rss {
	> ul {
		margin: 0;
		padding: 0;

		> li {
			list-style: none;
			margin-bottom: 25px;

			> .rsswidget {
				font-size: 1.25em;
				line-height: 1.2em;
				font-family: $alt-family;
				font-weight: 600;
				display: block;
				text-decoration: none; }

			> .rss-date {
				display: block;
				font-size: 0.8em;
				font-style: italic;
				margin-bottom: 15px; } } } }
