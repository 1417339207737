
// Canvas Animated
.menu-1-canvas {
	position: relative;
	transition: all 0.15s ease-in-out; }
.menu-1-open {
	overflow-x: hidden;
	transform: translateX(300px);
	transition: all 0.35s ease-in-out; }

#navigation {
	// Navigation Style 1
	&.navigation-style-1 {
		position: fixed;
		top: 0;
		left: -300px;
		width: 300px;
		height: 100%;
		background: $light-black;
		z-index: 100;
		transition: all .35s ease;

		> .container {
			width: 100%;
			margin: 0;
			padding: 0; }

		.nav-header {
			#logo {
				display: block;
				width: 85px;
				height: 85px;
				margin: 30px auto; }

			#nav-trigger {
				position: absolute;
				top: 65px;
				left: 330px;
				> a {
					color: $text-color;
					font-size: 1.3em; } }

			#nav-socials {
				display: none; } }

		.site-navigation {
			padding: 0;
			margin: 0;

			> li {
				list-style: none;
				> a {
					display: block;
					color: $text-color;
					text-decoration: none;
					font-family: $alt-family;
					padding: 10px 25px;
					transition: all .2s ease-in-out;

					> i {
						padding-right: 10px; }

					&:hover {
						color: $base-color;
						background: $light-black - 5; } }

				&.has-submenu {
					position: relative;

					&:after {
						content: "\f105";
						font-family: FontAwesome;
						position: absolute;
						top: 10px;
						right: 35px;
						transition: all .35s ease; }

					> ul {
						padding: 0;
						margin: 0;
						display: none;
						opacity: 0;
						overflow: hidden;
						transition: opacity .35s ease;

						> li {
							list-style: none;

							> a {
								display: block;
								font-size: .9em;
								color: $text-color;
								text-decoration: none;
								font-family: 'Montserrat', sans-serif;
								padding: 10px 25px;
								padding-left: 45px;
								transition: all .2s ease-in-out;

								&:hover {
									color: $base-color;
									background: $light-black - 5; } } } } }

				&.active {
					&:after {
						transform: rotate(90deg); }

					> ul {
						display: block;
						opacity: 1; } } } }

		&.open {
 } }			// transform: translateX(-100%)

	// Navigation Style 2

	&.navigation-style-2 {
		background-color: transparent;
		width: 100%;
		margin: 0 auto;
		margin-top: 50px;
		position: absolute;
		z-index: 100;

		> .container {
			position: relative; }

		.nav-header {
			#logo {
				float: left;
				color: $white;
				text-decoration: none;
				> h1 {
					font-size: 2em;
					line-height: 32px;
					font-weight: 400;
					margin: 5px 0; } }


			#nav-trigger {
				float: right;
				margin: 10px 0;
				padding-right: 30px;
				> a {
					color: $white;
					font-size: 1.3em;
					line-height: 25px; } }

			#nav-socials {
				float: right;
				border-left: 2px solid $white;
				margin: 10px 0;
				padding-left: 20px;

				> a {
					font-size: 1em;
					display: inline-block;
					margin: 0 3px;
					color: rgba($light-black, 0.5);
					transition: all 0.15s ease-in-out;

					&:hover {
						color: $white; } } } }

		.site-navigation {
			padding: 0;
			margin: 0;
			text-align: right;
			float: right;
			margin-right: 25px;
			transition: all .35s ease;

			> li {
				display: inline-block;
				opacity: 0;
				position: relative;
				top: -5px;
				transition: top .1s ease .1s, opacity .1s ease .1s;

				&:nth-child(2n) {
					transition: top .2s ease .2s, opacity .2s ease .2s; }
				&:nth-child(3n) {
					transition: top .3s ease .3s, opacity .3s ease .3s; }
				&:nth-child(4n) {
					transition: top .4s ease .4s, opacity .4s ease .4s; }
				&:nth-child(5n) {
					transition: top .5s ease .5s, opacity .5s ease .5s; }
				&:nth-child(6n) {
					transition: top .6s ease .6s, opacity .6s ease .6s; }
				&:nth-child(7n) {
					transition: top .7s ease .7s, opacity .7s ease .7s; }
				&:nth-child(8n) {
					transition: top .8s ease .8s, opacity .8s ease .8s; }
				&:nth-child(9n) {
					transition: top .9s ease .9s, opacity .9s ease .9s; }
				&:nth-child(10n) {
					transition: top 1.0s ease 1.0s, opacity 1.0s ease 1.0s; }
				&:nth-child(11n) {
					transition: top 1.1s ease 1.1s, opacity 1.1s ease 1.1s; }
				&:nth-child(12n) {
					transition: top 1.2s ease 1.2s, opacity 1.3s ease 1.3s; }

				> a {
					display: block;
					margin: 0px 15px;
					padding: 10px 0;
					font-family: $alt-family;
					text-decoration: none;
					color: $white;
					transition: all 0.15s ease-in-out;

					> i {
						display: none; }

					&:hover {
						color: $base-color + 55; } }

				&.has-submenu {
					position: relative;

					> ul {
						position: absolute;
						background-color: $light-black;
						padding: 0;
						margin: 0;
						min-width: 225px;
						text-align: left;
						top: 75px;
						opacity: 0;
						visibility: hidden;
						transition: all 0.15s ease-in-out;

						> li {
							list-style: none;
							> a {
								display: block;
								line-height: 3em;
								padding-left: 25px;
								padding-right: 25px;
								text-decoration: none;
								color: $text-color;

								&:hover {
									background-color: $light-black - 5;
									color: $base-color; } } } }

					&:hover {
						> ul {
							visibility: visible;
							top: 46px;
							opacity: 1; } } } } } }

	&.open {
		.site-navigation {
			> li {
				opacity: 1;
				top: 0; } } } }

@media (max-width: 992px) {
	#navigation {
		&.navigation-style-2 {
			margin-top: 0px;
			padding-top: 25px;
			transition: all 0.15s ease-in-out;

			.site-navigation {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, 50%);
				> li {
					display: block;
					text-align: center; } }

			&.open {
				height: 100%;
				background-color: rgba($light-black, 0.8); } } } }

@media (max-height: 500px) {
	#navigation {
		&.navigation-style-2 {
			.site-navigation {
				top: 50px;
				left: 50%;
				transform: translateX(-50%);
				> li {
					> a {
						font-size: .8em;
						line-height: 1.2em;
						padding: 5px 0; } } } } } }
@media only screen and (min-device-width: 320px) and (max-device-width: 736px) and (orientation: portrait) {
	#navigation {
		&.navigation-style-2 {
			.nav-header {
				#nav-socials {
					display: none; } } } } }
