.member-profile {
	padding: 25px;
	background-color: $white;
	text-align: center;
	box-shadow: 0px 2px 4px 0px rgba($light-black, 0.1);
	border-radius: 4px;
	margin-bottom: 15px;

	> .member-image {
		width: 160px;
		height: 160px;
		background: $dark-gray;
		margin: 0 auto;
		border-radius: 50%;
		padding: 4px;
		filter: grayscale(1);
		transition: all 0.15s ease-in-out;

		> img {
			border-radius: 50%;
			width: 100%;
			height: auto; } }

	> .member-info {
		padding: 25px 0px;

		> .member-name {
			margin-top: 0;
			font-size: 1.5em;
			margin-bottom: 5px;
			font-weight: 600;
			color: $light-black;
			transition: all 0.15s ease-in-out; } }

	> .member-social {
		padding-top: 25px;

		> a {
			display: inline-block;
			margin: 5px;
			color: $dark-gray;

			&:hover {
				color: $base-color;

				&.facebook {
					color: #3b5998; }
				&.twitter {
					color: #28a9e2; }
				&.pintarest {
					color: #cb2027; }
				&.dribbble {
					color: #e04c86; }
				&.googleplus {
					color: #d95232; }
				&.github {
					color: #333; }
				&.linkedin {
					color: #0077b5; } } } }

	&:hover {
	 	> .member-image {
	 		 filter: grayscale(0); }
	 	> .member-info {
	 		> .member-name {
	 			color: $base-color; } } } }

@media (max-width: 992px) {
	.member-profile {
		min-height: 475px; } }

@media (max-width: 767px) {
	.member-profile {
		min-height: inherit; } }
