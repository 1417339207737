/* Body */
body {
	font-family: $default-family;
	font-size: 16px;
	color: $text-color; }

/* Headings */
h1,h2,h3,h4,h6 {
	font-family: $alt-family;
	font-weight: 700; }

table {
    width: 100%; }

table tbody tr th, table tbody tr td {
    border: 1px solid #F0F0F0;
    padding: 5px 10px; }
