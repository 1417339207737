.aw-btn {
	display: inline-block;
	padding: 15px 35px;
	margin: 10px;
	background-color: $white;
	color: $base-color;
	border-radius: 4px;
	font-size: 1.125em;
	line-height: 1em;
	font-weight: 700;
	text-decoration: none;
	transition: all 0.15s ease-in-out;

	&:hover {
		text-decoration: none;
		background-color: $base-color;
		color: $white; }

	&:first-child {
		margin-left: 0; }
	&:last-child {
		margin-right: 0; }

	&.alt {
		font-size: 1em;
		background-color: $base-color;
		color: $white;
		border: 1px solid transparent;

		&:hover {
			border-color: $base-color;
			background-color: $white;
			color: $base-color; } }
	&.viewmore {
		border: 4px solid $dark-gray; } }

.gradient {
	.overlay {
		.aw-btn {
			background-color: transparent;
			border: 2px solid $white;
			color: $white;

			&.alt, &:hover {
				background-color: $white;
				color: $base-color; } } } }
