#google-maps {
	min-height: 460px;
	width: 100%; }

.contact-details {
	background-color: $body-color;
	padding: 30px;
	position: relative;
	box-shadow: 2px 0 4px rgba($light-black, 0.4);
	z-index: 3;
	color: $text-color;


	.contact-title {
		font-size: 1.375em;
		font-weight: normal;
		margin-bottom: 10px;
		color: $light-black; }

	.contact-items {
		padding: 0px;
		padding-bottom: 30px;
		border-bottom: 1px solid $light-gray;

		> li {
			list-style: none;
			padding-left: 32px;
			padding-top: 5px;
			padding-bottom: 5px;
			position: relative;


			&:before {
				font-family: FontAwesome;
				font-size: 1.25em;
				color: $base-color;
				position: absolute;
				left: 0px;
				top: 8px; } } }

	.social-profiles {
		padding: 15px 0px;

		> li {
			display: inline-block;

			> a {
				display: block;
				width: 24px;
				height: 24px;
				position: relative;
				text-align: center;

				> i {
					font-size: 1.25em; }


				> span {
					opacity: 0;
					background: #000;
					color: $white;
					border-radius: 4px;
					font-size: 0.75em;
					line-height: 1em;
					padding: 3px 5px;
					position: absolute;
					top: -20px;
					left: 50%;
					transform: translateX(-50%);
					transition: all 0.15s ease-in-out;

					&:after {
						top: 100%;
						left: 50%;
						border: solid transparent;
						content: " ";
						height: 0;
						width: 0;
						position: absolute;
						pointer-events: none;
						border-top-color: #000;
						border-width: 5px;
						margin-left: -5px; } }

				&:hover {
					> span {
					 top: -30px;
					 opacity: 1; } } }

			// Profile Colors
			&[class*="facebook"] {
				> a {
					color: #3b5998;
					> span {
						background-color: #3b5998;

						&:after {
							border-top-color: #3b5998; } } } }
			&[class*="twitter"] {
				> a {
					color: #28a9e2;
					> span {
						background-color: #28a9e2;

						&:after {
							border-top-color: #28a9e2; } } } }
			&[class*="pinterest"] {
				> a {
					color: #cb2027;
					> span {
						background-color: #cb2027;

						&:after {
							border-top-color: #cb2027; } } } }
			&[class*="dribbble"] {
				> a {
					color: #e04c86;
					> span {
						background-color: #e04c86;

						&:after {
							border-top-color: #e04c86; } } } }
			&[class*="vimeo"] {
				> a {
					color: #1ab7ea;
					> span {
						background-color: #1ab7ea;

						&:after {
							border-top-color: #1ab7ea; } } } }
			&[class*="googleplus"] {
				> a {
					color: #d95232;
					> span {
						background-color: #d95232;

						&:after {
							border-top-color: #d95232; } } } } } } }

.contact-container {
	background-color: rgba($dark-gray, 0.1);
	padding: 30px;

	.contact-title {
		font-size: 1.375em;
		font-weight: normal;
		margin: 0;
		margin-bottom: 25px; }

	.contact-form {
		p {
			margin: 0; }

		input,
		textarea {
			display: block;
			width: 100%;
			border: 1px solid transparent;
			border-radius: 4px;
			background-color: $deep-gray;
			padding: 10px 20px;
			margin-bottom: 15px;
			transition: all 0.15s ease-in-out;
			resize: none;

			&:active,
			&:focus {
				border-color: $base-color;
				outline-color: transparent; }

			&::-webkit-input-placeholder {
				color: $text-color; }

			&:-moz-placeholder {
				color: $text-color; }

			&::-moz-placeholder {
				color: $text-color; }

			&:-ms-input-placeholder {
				color: $text-color; }

			&[type="submit"] {
				display: inline-block;
				width: auto;
				background-color: $base-color;
				color: $white;
				font-size: 1.125em;
				font-weight: 600;
				text-transform: uppercase;

				&:hover {
					background-color: $white;
					color: $base-color; } } }


		textarea {
			height: 150px; } } }

.gradient {
	.contact-container {
		.contact-form {
			input,
			textarea {
				color: $light-black;
				background-color: $white;

				&[type="submit"] {
					color: $base-color;
					&:hover {
						background-color: $base-color;
						color: $white;
						border-color: $white; } } } } } }
