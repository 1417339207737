.logo-items {
	text-align: center;

	> .item {
		display: inline-block;
		padding: 30px;

		> a {
			opacity: 0.5;
			filter: grayscale(1);

			&:hover {
				opacity: 1;
				filter: grayscale(0); } } } }
