.pricing-plan {
	background: $white;
	border-radius: 4px;
	box-shadow: 0px 1px 5px 0px rgba($light-black, 0.15);
	margin-top: 15px;

	> .head {
		padding: 40px 25px;
		background-color: $base-color;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		color: $white;
		@include clearfix();

		> .plan-name {
			font-family: $alt-family;
			font-size: 1.375em;
			line-height: 1em;
			font-weight: 700;
			width: 70%;
			float: left;

			> .plan-desc {
				font-family: $default-family;
				font-size: 0.7em;
				font-weight: 400;
				color: $white - 17; } }
		> .plan-price {
			position: relative;
			font-family: $alt-family;
			font-size: 3em;
			line-height: 0.75em;
			font-weight: 700;
			width: 30%;
			float: left;

			> .currency {
				position: absolute;
				top: 7px;
				left: -12px;
				font-size: 0.45em;
				font-family: $default-family;
				font-weight: 400;
				color: $light-gray - 30; }

			> .cycle {
				font-family: $default-family;
				font-weight: 400;
				font-size: 0.31em;
				line-height: 1em;
				color: $light-gray - 17;
				position: absolute;
				top: 40px; } } }

	> .content {
		border-top: 4px solid $light-gray;
		padding: 25px;

		>  ul {
			padding: 0px;
			margin: 0px;

			&.plan-feature {
				> li {
					list-style: none;
					border-bottom: 1px solid $light-gray;
					padding: 10px 0;

					> i {
						padding-right: 10px;
						color: $base-color; }

					&.disable {
						> i {
							color: $text-color; } } } } } }

	> .footer {
		padding: 25px;
		padding-top: 0px;
		text-align: center; }

	&.active {
		margin-top: 0px;

		> .head {
			padding-top: 50px; }
		> .footer {
			padding-bottom: 40px; } } }

@media (max-width: 992px) {
	.pricing-plan {
		&.active {
			margin-top: 15px; } } }
