.dark {
	background-color: $dark-color-1;

	// Section Title
	.section-title {
		color: $white; }

	[data-background="gray"] {
		background-color: $dark-color-2; }

	// Achieves
	.achieve-item {
		> .icon {
			background-color: $dark-color-2;
			&:after {
				background-color: $dark-color-1; }
			> i {
				color: $text-color; } }
		> .inside {
			background-color: $dark-color-2;
			color: $base-color;
			// > .content
			> .name {
				color: $text-color; } } }
	// Features
	.feature-items {
		.item {
			> .icon {
				> i {
					color: $text-color - 50; } } }

		&.style-2 {
			.item {
				> .icon {
					> i {
						color: $base-color; } }
				&:hover {
					> .icon {
						> i {
							color: $white; } } } } }
		&.style-3 {
			.item {
				> .icon {
					> i {
						color: $base-color; } }
				> .content {
					> h3 {
						color: $base-color; } }
				&:hover {
					> .icon {
						> i {
							color: $white; }
						&:after {
							background-color: $base-color; } } } } } }

	// Team Members
	.member-profile {
		background-color: $dark-color-1;
		box-shadow: 0px 2px 4px 0px rgba($light-black, 0.2);
		> .member-info {
			> .member-name {
				color: $base-color; } }
		&:hover {
			> .member-info {
				> .member-name {
					color: $white; } } } }
	// Pricing Tables
	.pricing-plan {
		background-color: $dark-color-1;
		box-shadow: 0px 2px 4px 0px rgba($light-black, 0.2);
		> .content {
			border-top: 4px solid $dark-color-2;
			>  ul {
				&.plan-feature {
					> li {
						border-bottom: 1px solid $text-color; } } } } }
	// Testimonials
	.testimonial-items {
	    .item {
	        > .content {
	            background: $dark-color-1;
	            &:before {
	            	border-right-color: $dark-color-1; } } } }
	.overflow-testimonials {
		background-image: url('../images/testimonials-lg-bg-dark.png');
		> .item {
			> .image {
				background-color: $dark-color-2; }
			> .content {
				border: 1px solid $dark-color-2;
				border-bottom: none;
				background-color: rgba($dark-color-1,0.8);
				box-shadow: 0px 2px 4px 0px rgba($light-black, 0.5);
				&:before,
				&:after {
					border-bottom-color: $dark-color-1; }
				&:before {
					border-bottom-color: $dark-color-2; } }
			&:nth-child(4),
			&:nth-child(5) {
	            > .content {
	                &:before,
	                &:after {
	                    border-top-color: $dark-color-1;
	                    border-bottom-color: transparent; }

	                &:before {
	                    border-top-color: $dark-color-2; } } } } }

	// Blogs
	.article-item {
		background-color: $dark-color-1;
		box-shadow: 0px 2px 4px 0px rgba($light-black, 0.2);
		> .entry-title {
			> a {
				color: $base-color;
				&:hover {
					color: $white; } } } }

	.timeline {
		&:before {
			background-color: $dark-color-1; }
		.article-item {
			&:before {
				background-color: $dark-color-2;
				border: 5px solid $dark-color-1; }

			&:hover {
				&:before {
					background-color: $base-color; } }

			> .article-head {
				&:before {
					border-left-color: rgba($dark-color-2, 0.4); }

				&:after {
					border-left-color: $dark-color-1; } }

			&:nth-child(even) {
				> .article-head {
					&:before {
						border-right-color: rgba($dark-color-2, 0.4); }

					&:after {
						border-left-color: transparent;
						border-right-color: $dark-color-1; } } } } }
	// Contact
	.contact-details {
		background-color: $dark-color-1;
		.contact-title {
			color: $white; }
		.contact-items {
			border-bottom-color: $dark-color-2; } }
	.aw-btn {
		&.viewmore {
			border: 4px solid $dark-color-1;
			background-color: $dark-color-2; } } }
