#wp-calendar {
	width: 100%;
	caption {
		text-align: right;
		color: #333;
		margin-top: 10px;
		margin-bottom: 15px; }

	thead {

		th {
			padding-bottom: 10px;
			font-size: 1em;
			text-align: center; } }

	tbody {
		color: #aaa;

		td {
			background: $light-gray;
			border: 1px solid $white;
			text-align: center;
			padding: 8px;
			transition: all 0.15s ease-in-out;

			&:hover {
				background: $white; } }
		.pad {
			background: none; } }

	tfoot {
		#next {
			text-transform: uppercase;
			text-align: right; }

		#prev {
			text-transform: uppercase;
			padding-top: 10px; } } }
